<div class='sections' *ngFor="let section of _testModelData.sections ">
    <div>
        <div class='section-name'>{{ section.name }}</div>
        <div class='section-models'>
            <div *ngFor="let armodel of section.models ">
                <app-model-detail [ARModelData]=armodel></app-model-detail>
            </div>
        </div>

    </div>
</div>