<app-header></app-header>
<app-model-listing></app-model-listing>

<!-- 
 Old prototype component
<div>
  <h1>Aptiv SVA Virtual Tool Prototype</h1>
  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. </p>
  <app-arviewer></app-arviewer>
</div> 
-->