import { Component, OnInit, ElementRef } from '@angular/core';
import * as THREE from 'three';
import { FBXLoader } from 'three/examples/jsm/loaders/FBXLoader'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls'

import Stats from 'three/examples/jsm/libs/stats.module';
import { CameraHelper } from 'three';

@Component({
  selector: 'app-arviewer',
  templateUrl: './arviewer.component.html',
  styleUrls: ['./arviewer.component.scss']
})
export class ARViewerComponent implements OnInit {

  private container;
  private stats;
  private controls;
  private camera;
  private scene;
  private renderer;
  private light;
  private clock = new THREE.Clock();
  private mixer;
  private CANVAS_PADDING = 20;
  private MAX_WIDTH = 1600;

  isMobile;
  constructor() { }

  ngOnInit(): void {
    this.isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    console.log("ARViewerComponent -> ngOnInit -> isMobile", this.isMobile)
    if (this.isMobile) {
      /* your code here */
    }
    
    this.init();
  }

  get innerHeight(){
    return window.innerHeight - 300;
  }

  get innerWidth(){
    return Math.min(window.innerWidth - this.CANVAS_PADDING, this.MAX_WIDTH);
  }
  
  init() {
    const htmlCanvas = document.getElementById( 'arviewer-canvas' ) as HTMLCanvasElement;
    htmlCanvas.style.height = `${this.getRemainingPageHeight()}px`;
    window.addEventListener( 'resize', this.onWindowResize.bind(this), false );

    this.camera = new THREE.PerspectiveCamera( 45, this.innerWidth / this.innerHeight, 1, 2000 );
    this.camera.position.set( 100, 200, 300 );

    this.scene = new THREE.Scene();
    this.scene.background = new THREE.Color( 0xa0a0a0 );
    this.scene.fog = new THREE.Fog( 0xa0a0a0, 200, 1000 );

    this.light = new THREE.HemisphereLight( 0xffffff, 0x444444 );
    this.light.position.set( 0, 200, 0 );
    this.scene.add( this.light );

    this.light = new THREE.DirectionalLight( 0xffffff );
    this.light.position.set( 0, 200, 100 );
    this.light.castShadow = true;
    this.light.shadow.camera.top = 180;
    this.light.shadow.camera.bottom = - 100;
    this.light.shadow.camera.left = - 120;
    this.light.shadow.camera.right = 120;
    this.scene.add( this.light );

    // this.scene.add( new CameraHelper( this.light.shadow.camera ) );

    // ground
    let mesh = new THREE.Mesh( new THREE.PlaneBufferGeometry( 2000, 2000 ), new THREE.MeshPhongMaterial( { color: 0x999999, depthWrite: false } ) );
    mesh.rotation.x = - Math.PI / 2;
    mesh.receiveShadow = true;
    this.scene.add( mesh );

    let grid = new THREE.GridHelper( 2000, 20, 0x000000, 0x000000 );
    this.scene.add( grid );

    let loader = new FBXLoader();
    loader.load( '../assets/models/Standard-connector.fbx', ( object ) => {

      this.mixer = new THREE.AnimationMixer( object );

      // var action = this.mixer.clipAction( object.animations[ 0 ] );
      // action.play();
      let scale = 10;
      object.scale.set(scale, scale, scale);
      object.position.set( 0, 100, 0);

      object.traverse( ( child ) =>{

        if ( child.type === 'Mesh' ) {
          // console.log( child.type );
          // console.log( child );
          // console.log( child.toJSON() );
          child.castShadow = true;
          child.receiveShadow = true;
        }

      } );

      this.scene.add( object );

    },
    ( event: ProgressEvent )=>{
      // console.log("ARViewerComponent -> init -> event", event)
    }
    );

    this.renderer = new THREE.WebGLRenderer( { antialias: true, canvas:htmlCanvas} );
    this.renderer.setPixelRatio( window.devicePixelRatio );
    this.renderer.setSize( this.innerWidth, this.innerHeight );
    this.renderer.shadowMap.enabled = true;

    this.controls = new OrbitControls( this.camera, this.renderer.domElement );
    this.controls.target.set( 0, 100, 0 );
    this.controls.update();

     // stats
    this.stats = Stats();
    const canvasContainerRef = document.getElementById( 'arviewer-stats-container' );
    canvasContainerRef.appendChild( this.stats.dom );
    
    this.stats.domElement.style.position = 'unset';
    this.animate = this.animate.bind(this);
    this.animate();    
  }

  getRemainingPageHeight(){
    return window.innerHeight - 300;
  }

  onWindowResize() {
    const height = this.innerHeight;
    this.camera.aspect = this.innerWidth / height;
    this.camera.updateProjectionMatrix();
    this.renderer.setSize( this.innerWidth, height );
  }

  animate() {
    requestAnimationFrame( this.animate );
    var delta = this.clock.getDelta();
    if ( this.mixer ) this.mixer.update( delta );
    this.renderer.render( this.scene, this.camera );
    this.stats.update();
  }


}
