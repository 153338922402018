import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { ARViewerComponent } from './arviewer/arviewer.component';
import { HeaderComponent } from './component/header/header.component';
import { ModelListingComponent } from './component/model-listing/model-listing.component';
import { ModelDetailComponent } from './component/model-detail/model-detail.component';

@NgModule({
  declarations: [
    AppComponent,
    ARViewerComponent,
    HeaderComponent,
    ModelListingComponent,
    ModelDetailComponent
  ],
  imports: [
    BrowserModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
