<div class='model'>


    <div class='model-name'>{{this.model.name}}</div>

    <div class='tags'>
        <div *ngFor="let tag of this.model.tags">
            <div class='pill' ngClass='{{tag | lowercase}}'>{{tag}}</div>
        </div>
    </div>
    <div class='preview-container'>
        <img class='preview' src='{{this.assetsPath}}{{this.assetsBasename}}-thumbnail.png'>
        <div class='ar-prompt'>

            <div *ngIf='!isMobile' class='qr-code'>
                <!-- <div>View in AR</div> -->
                <img src='{{this.assetsPath}}{{this.assetsBasename}}-qrcode.png'>
            </div>

            <!-- <div *ngIf='isMobile'>
                test
                <a href="{{modelPath}}" rel="ar" #arAnchor>{{state}}</a>
                <div (click)="onDivClick()">
                    <img src='../../assets//images/ARKit_Glyph_Clear.png'>
                    <div> </div>
                </div>
            </div> -->

            <div *ngIf='isMobile'>
                <a href="{{modelPath}}" rel="ar">
                    <img src='../../assets/images/ARKit_Glyph_Clear.png'>
                    <div> </div>
                </a>
            </div>
        </div>
    </div>

</div>