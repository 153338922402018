import { Component, OnInit, Input, ViewChild } from '@angular/core';

interface IARModel {
  name: string;
  tags: string;
}

@Component({
  selector: 'app-model-detail',
  templateUrl: './model-detail.component.html',
  styleUrls: ['./model-detail.component.scss']
})
export class ModelDetailComponent implements OnInit {

  isMobile: boolean;
  @Input("ARModelData") model: IARModel;
  @ViewChild('arAnchor') arAnchor;
  state = '';

  constructor() {
    this.isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  }

  ngOnInit(): void {
    console.log("ModelDetailComponent -> model", this.model);
  }

  get assetsPath(): string {
    return this.model['assets-path'];
  }

  get assetsBasename(): string {
    return this.model['assets-basename'];
  }

  get modelPath(): string {
    return `${this.assetsPath}${this.assetsBasename}.usdz`;
  }

  onDivClick() {

    this.state = 'loading';

    fetch(this.modelPath, { credentials: 'include' })
      .then(response => response.blob())
      .then(blob => this.convertBlobToBase64(blob))
      .then(base64 => {
        this.state = 'loaded';
    
        this.arAnchor.src = base64;
        this.arAnchor.nativeElement.click();
      });
  }

  fetchAsBlob(url) {
    return fetch(url, { credentials: 'include' })
      .then(response => response.blob());
  }

  convertBlobToBase64(blob) {
    this.state = 'converting';

    return new Promise((resolve, reject) => {
      const reader = new FileReader;
      reader.onerror = reject;
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.readAsDataURL(blob);
    });
  }

}
