import { Component, OnInit } from '@angular/core';
import TestModelData from '../../../assets/json/test-models.json';

console.log("TestModelData", TestModelData)

@Component({
  selector: 'app-model-listing',
  templateUrl: './model-listing.component.html',
  styleUrls: ['./model-listing.component.scss']
})
export class ModelListingComponent implements OnInit {

  _testModelData = TestModelData;
  
  constructor() { }

  ngOnInit(): void {
  }

}
