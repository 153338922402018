<h2>Standard-connector.fbx</h2>
<div id='canvas-container'>
    <div id='arviewer-stats-container'></div>
    <canvas id='arviewer-canvas'></canvas>


    <div class='ar-prompt'>

        <div *ngIf='!isMobile' class='qr-code'>
            <img src='../../assets//images/qr-code-Standard-connector.usdz.png'>
            <div>Scan the QR Code to View in Augmented Reality</div>
        </div>
    
        <div *ngIf='isMobile' class='ar-icon'>
            <a href="../../assets//models/Standard-connector.usdz" rel="ar">
                <img src='../../assets//images/ARKit_Glyph_Clear.png'>
                <div>Touch icon to View in Augmented Reality</div>
            </a>
        </div>
    </div>
     
</div>
